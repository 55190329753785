import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Maytronics from '../svg/maytronics'
import Spinner from '../utils/spinner'
import {
	OtherPostsSection,
	OtherPostsHead,
	OtherPostsContainer,
	SingePostContainer,
	ImageContainer,
	SvgContainer,
	PostDetailsContainer,
	PostCat,
	PostTtl,
	PostDateTime,
} from './styles'

const OtherPosts = ({ otherData, checkedLocale }) => {
	const [data, setData] = useState(null)

	useEffect(() => {
		setData(otherData)
	})

	return (
		<OtherPostsSection>
			<OtherPostsHead>You may also like</OtherPostsHead>
			<OtherPostsContainer>
				{data ? (
					data.map((post, i) => {
						if(post.category !== "Mineral Swim"){
							return (
								<Link to={`${checkedLocale}/${post.slug}/`} key={i}>
									<SingePostContainer>
										{post?.image ? (
											<ImageContainer
												bg={
													post?.image?.file?.url +
													'?fm=webp&w=300&h=160&fit=fill&f=top&q=70'
												}
											></ImageContainer>
										) : (
											<SvgContainer>
												<Maytronics width="100%" height="100" />
											</SvgContainer>
										)}
										<PostDetailsContainer>
											<PostCat>{post.category}</PostCat>
											<PostTtl>{post.seoTitle}</PostTtl>
											<PostDateTime>{post.dateAndTime}</PostDateTime>
										</PostDetailsContainer>
									</SingePostContainer>
								</Link>
							)
						}
					})
				) : (
					<Spinner />
				)}
			</OtherPostsContainer>
		</OtherPostsSection>
	)
}

export default OtherPosts
