import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Maytronics from '../svg/maytronics'
import NewsSidebar from '../sidebar'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Spinner from '../utils/spinner'
import DummyLogo from '../../images/300x200.png'
import {
	MainSection,
	StorySidebarContainer,
	StoryContainer,
	Main,
	MainTextContainer,
	MainImageContainer,
	Texts,
	BlockQuote,
	Quote,
	QuoteAuthor,
	SubHead,
	EmbeddedAssetContainer,
	ImageWrapContainer,
	TagContainer,
	TagItem,
	NextPrevContainer,
	PrevContainer,
	NextContainer,
	NextPrevTitle,
	RelatedPostContainer,
	RelatedPostHead,
	PostsContainer,
	RelatedPost,
	RelatedPostTitle,
	RelatedDate,
	MainHead1,
	MainHead2,
	MainHead4,
	MainHead5,
	MainHead6,
	ListItem,
	UnorderedList,
	ContentLink,
	CreatedElement,
} from './styles'

const createHtml = text => {
	return { __html: text }
}

const MainContent = ({ contents, locale, defaultlocale, checkedLocale }) => {
	const options = {
		renderMark: {
			[MARKS.CODE]: text => {
				return <CreatedElement dangerouslySetInnerHTML={createHtml(text)} />
			},
		},
		renderNode: {
			[BLOCKS.HEADING_1]: (node, children) => {
				return <MainHead1>{children}</MainHead1>
			},
			[BLOCKS.HEADING_2]: (node, children) => {
				return <MainHead2>{children}</MainHead2>
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				return <SubHead>{children}</SubHead>
			},
			[BLOCKS.HEADING_4]: (node, children) => {
				return <MainHead4>{children}</MainHead4>
			},
			[BLOCKS.HEADING_5]: (node, children) => {
				return <MainHead5>{children}</MainHead5>
			},
			[BLOCKS.HEADING_6]: (node, children) => {
				return <MainHead6>{children}</MainHead6>
			},
			[BLOCKS.QUOTE]: (node, children) => {
				const text = node.content[0].content[0].value
				const auth = node.content[1].content[0].value
				return (
					<BlockQuote>
						<Quote>{text}</Quote>
						<QuoteAuthor>{auth}</QuoteAuthor>
					</BlockQuote>
				)
			},
			[BLOCKS.PARAGRAPH]: (node, children) => {
				return <Texts>{children}</Texts>
			},
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => {
				const checkfile = node?.data?.target?.fields?.file[
					locale
				]?.contentType.indexOf('video')
				return checkfile < 0 || checkfile === undefined ? (
					<EmbeddedAssetContainer>
						<img
							src={
								(node?.data?.target?.fields?.file[locale]
									? node?.data?.target?.fields?.file[locale]?.url
									: node?.data?.target?.fields?.file[defaultlocale]?.url) +
								'?fm=webp&fit=fill&f=center&q=70'
							}
							alt={
								node?.data?.target?.fields?.description[locale]
									? node?.data?.target?.fields?.description[locale]
									: node?.data?.target?.fields?.description[defaultlocale]
							}
							loading="lazy"
							width={
								node?.data?.target?.fields?.file[locale]?.details?.image?.width
							}
							height={
								node?.data?.target?.fields?.file[locale]?.details?.image?.height
							}
						/>
					</EmbeddedAssetContainer>
				) : (
					<video
						className="lazy"
						controls
						preload="none"
						poster=""
						width="100%"
						height="100%"
						loop
					>
						<source
							src={
								node?.data?.target?.fields?.file[locale]
									? node?.data?.target?.fields?.file[locale]?.url
									: node?.data?.target?.fields?.file[defaultlocale]?.url
							}
							type="video/mp4"
						></source>
					</video>
				)
			},
			[INLINES.ASSET_HYPERLINK]: (node, children) => {
				const text = node.content[0].value
				return (
					<>
						<ImageWrapContainer>
							<img
								src={
									(node.data.target.fields?.file[locale]
										? node.data.target.fields?.file[locale]?.url
										: node.data.target.fields?.file[defaultlocale]?.url) +
									'?fm=webp&w=720&fit=fill&f=center&q=70'
								}
								alt={
									node.data.target.fields?.description[locale]
										? node.data.target.fields?.description[locale]
										: node.data.target.fields?.description[defaultlocale]
								}
								loading="lazy"
							/>
						</ImageWrapContainer>
						{text.split('\\n').map((item, i) => {
							return <span key={i}>{item}</span>
						})}
					</>
				)
			},
			[INLINES.HYPERLINK]: (node, children) => {
				// console.log(node)
				return (
					<ContentLink href={node?.data?.uri} target="_blank">
						{children}
					</ContentLink>
				)
			},
			[BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
			[BLOCKS.UL_LIST]: (node, children) => (
				<UnorderedList>{children}</UnorderedList>
			),
		},
	}

	const { image, content, tag, filteredNextPrev, randomValues } = contents

	var next = "";
	var previous = "";
	if(filteredNextPrev.length != "0"){
		var next = filteredNextPrev[0].next;
		var previous = filteredNextPrev[0].previous;
	}
	
	const [data, setData] = useState(null)
	useEffect(() => {
		setData(randomValues)
	})

	return (
		<MainSection>
			<StorySidebarContainer>
				<StoryContainer>
					<Main>
						<MainImageContainer>
							{image ? (
								<img
									src={image.file.url + '?fm=webp&h=400&fit=fill&f=top&q=70'}
									alt={image?.description}
									width={image?.file.details.image.width}
									height={image?.file.details.image.height}
									loading="lazy"
								/>
							) : (
								<Maytronics width="100%" height="250" />
							)}
						</MainImageContainer>
						<MainTextContainer>
							{content ? documentToReactComponents(content.json, options) : ''}
						</MainTextContainer>
						<TagContainer>
							{tag ? 
								tag.map((item, i) => {
									if(item !== "Mineral Swim"){
										return <TagItem key={i}>{item}</TagItem>
									}
								})
						: ''}
						</TagContainer>

						<NextPrevContainer>
							<PrevContainer>
								{previous ? (
									<>
										<Link to={`${checkedLocale}/${previous.slug}/`}>
											<p>PREVIOUS</p>
											<NextPrevTitle>{previous.seoTitle}</NextPrevTitle>
										</Link>
									</>
								) : (
									''
								)}
							</PrevContainer>
							<NextContainer>
								{next ? (
									<>
										<Link to={`${checkedLocale}/${next.slug}/`}>
											<p>NEXT</p>
											<NextPrevTitle>{next.seoTitle}</NextPrevTitle>
										</Link>
									</>
								) : (
									''
								)}
							</NextContainer>
						</NextPrevContainer>

						<RelatedPostContainer>
							<RelatedPostHead>Related Posts</RelatedPostHead>
							<PostsContainer>
								{data ? (
									data
										.filter(x => {
											return x != undefined
										})
										.map((related, i) => {
											const {
												slug,
												seoTitle,
												dateAndTime,
												image: relatedPostImage,
											} = related
											return (
												<RelatedPost
													key={i}
													bg={
														relatedPostImage
															? relatedPostImage.file.url +
															'?fm=webp&w=280&h=180&fit=fill&f=top&q=70'
															: DummyLogo
													}
												>
													<Link to={`${checkedLocale}/${slug}/`}>
														<RelatedPostTitle>{seoTitle}</RelatedPostTitle>
														<RelatedDate>{dateAndTime}</RelatedDate>
													</Link>
												</RelatedPost>
											)
										})
								) : (
									<Spinner />
								)}
							</PostsContainer>
						</RelatedPostContainer>
					</Main>
				</StoryContainer>
				<NewsSidebar categoryLocale={locale} checkedLocale={checkedLocale} />
			</StorySidebarContainer>
		</MainSection>
	)
	
}

export default MainContent
