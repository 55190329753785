import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'
import Layout from '../components/layout'
import Meta from '../components/meta'
import Hero from '../components/single-post/hero'
import MainContent from '../components/single-post/main-content'
import OtherPosts from '../components/single-post/other-posts'
import localeCheck from '../components/utils/locale-check'

const PostPageTemplate = ({
	data: { data, nextPrev, related, other },
	pageContext,
}) => {
	const { slug, locale, defaultLocale } = pageContext
	const [{ node }] = filterLocale(data.edges, locale)

	const getNode = filteredArray => {
		return filteredArray.map((item, i) => {
			return item.node
		})
	}

	const shuffledAndFiltered = arr => {
		for (let i = arr.length - 1; i > 0; i--) {
			let rnd = Math.floor(Math.random() * i)
			let temp = arr[i]
			arr[i] = arr[rnd]
			arr[rnd] = temp
		}
		return arr
	}

	const filterFieldValue = nextPrev.group.filter(x => {
		if (x.fieldValue === locale) {
			return x
		}
	})
	const [{ edges }] = filterFieldValue
	const filteredNextPrev = edges.filter(data => {
		if (slug === data.node.slug) {
			return data
		}
	})
	const filteredRelated = related.group.filter(x => {
		if (x.fieldValue === node.category) {
			return x
		}
	})

	const filteredRelatedByLocale =
		filteredRelated.length <= 0
			? false
			: filterLocale(filteredRelated[0].edges, locale)

	let filteredRelatedByNode = Array.isArray(filteredRelatedByLocale)
		? getNode(filteredRelatedByLocale)
		: []
	let randomValues = []
	if (filteredRelatedByNode.length !== 0 || filteredRelatedByNode.length > 1) {
		const [first, second, third, ...rest] = shuffledAndFiltered(
			filteredRelatedByNode
		)
		randomValues = [first, second, third]
	} else {
		randomValues
	}

	const otherNode = getNode(other.edges)
	const filterOtherLocale = [...otherNode].filter(x => {
		if (x.node_locale === locale) {
			return x
		}
	})
	const otherData = [...filterOtherLocale]
	const shuffledData = shuffledAndFiltered([...otherData])
	const otherPosts =
		shuffledData.length > 8 ? [...shuffledData].slice(0, 8) : [...shuffledData]

	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		category,
		dateAndTime,
		image,
		content,
		tag,
	} = node
	const postContent = {
		image,
		content,
		tag,
		filteredNextPrev,
		randomValues,
	}

	const checkedLocale = localeCheck(locale, defaultLocale)

	return (
		<Layout>
			<Meta
				title={seoTitle ? seoTitle : ''}
				keywords={seoKeywords ? seoKeywords : []}
				description={seoDescription ? seoDescription : ''}
				lang={locale}
				slug={slug}
			/>
			<Hero category={category} title={seoTitle} DateTime={dateAndTime} />
			<MainContent
				locale={locale}
				defaultlocale={defaultLocale}
				contents={postContent}
				checkedLocale={checkedLocale}
			/>
			<OtherPosts otherData={otherPosts} checkedLocale={checkedLocale} />
		</Layout>
	)
}

export const PostPageQuery = graphql`
	query PostPageBySlug($slug: String!) {
		data: allContentfulPost(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					category
					dateAndTime(formatString: "DD MMMM, YYYY")
					tag
					image {
						description
						file {
							url
							details {
								image {
									height
									width
								}
							}
						}
					}
					content {
						json
					}
				}
			}
		}
		nextPrev: allContentfulPost(
			filter: { slug: { ne: null }, category: { ne: "Mineral Swim" } }
			sort: { fields: dateAndTime, order: DESC }
		) {
			group(field: node_locale) {
				edges {
					node {
						slug
					}
					next {
						slug
						seoTitle
						category
					}
					previous {
						slug
						seoTitle
						category
					}
				}
				fieldValue
			}
		}
		related: allContentfulPost(
			sort: { fields: dateAndTime, order: DESC }
			filter: { slug: { nin: [null, $slug] } }
		) {
			group(field: category) {
				fieldValue
				edges {
					node {
						slug
						seoTitle
						dateAndTime(formatString: "DD MMMM, YYYY")
						node_locale
						image {
							file {
								url
							}
						}
					}
				}
			}
		}
		other: allContentfulPost(filter: { slug: { nin: [null, $slug] } }) {
			edges {
				node {
					category
					node_locale
					seoTitle
					slug
					dateAndTime(formatString: "DD MMMM, YYYY")
					image {
						file {
							url
						}
					}
				}
			}
		}
	}
`

export default PostPageTemplate
