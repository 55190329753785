import React from 'react'
import {
	HeroSection,
	Category,
	DateAndTime,
	Title,
} from './styles'

const Hero = ({ category, title, DateTime }) => {
	return (
		<HeroSection>
			<Category>{category}</Category>
			<Title>{title}</Title>
			<DateAndTime>{DateTime}</DateAndTime>
		</HeroSection>
	)
}

export default Hero
